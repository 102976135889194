<template>
  <div
    id="app"
    class="form-cruise"
  >
    <div class="cruise-search-form__header">
      <div class="cruise-search-form__clain">
        <span>Vyhledejte si váš zájezd</span>
      </div>
      <div class="cruise-search-form__logo">
        <img
          alt="MSC logo"
          class="img-responsive"
          src="~/assets/logo-msc-neg.png"
        >
      </div>
    </div>
    <div class="form-group">
      <el-select
        v-model="kind"
        placeholder="Zvolte typ zájezdu"
        ref="kind"
      >
        <el-option
          v-for="kind in kinds"
          :key="kind.id"
          :label="kind.title"
          :value="kind.id"
        />
      </el-select>
    </div>
    <div class="form-group">
      <el-select
        v-model="destination"
        placeholder="1. Vyberte lokalitu"
        ref="destination"
      >
        <el-option
          v-for="item in destinations"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        />
      </el-select>
    </div>
    <div
      class="form-group"
      v-if="showShip"
    >
      <el-select
        v-model="ship"
        placeholder="2. Zvolte loď"
        clearable
        ref="ship"
      >
        <el-option
          v-for="item in ships"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        />
      </el-select>
    </div>
    <div
      class="form-group"
      v-if="showPort"
    >
      <el-select
        v-model="port"
        placeholder="3. Zvolte přístav vyplutí"
        clearable
        ref="port"
      >
        <el-option
          v-for="item in ports"
          :key="item.code"
          :label="item.name"
          :value="item.code"
        />
      </el-select>
    </div>
    <div class="form-group">
      <div class="el-form-item" >
        <label class="el-form-item__label select-cruise__label">Termín</label>
        <div class="el-form-item__content">
          <el-date-picker
            v-model="dateRange"
            type="daterange"
            start-placeholder="Od"
            end-placeholder="Do"
            value-format="dd.MM.yyyy"
            format="dd.MM.yyyy"
          />
        </div>
      </div>
    </div>
    <div
      class="el-form-item el-form-item--slider"
      v-if="showPrice"
    >
      <label class="el-form-item__label select-cruise__label">Cena</label>
      <div class="form-group">
        <div class="price-slider-box">
          <el-input
            placeholder="Od"
            v-model.number="priceRangeFrom"
            class="price-slider__price"
          >
            <template slot="suffix">Kč</template>
          </el-input>
          <div class="wrapper">
            <el-slider
              v-model="priceRange"
              range
              show-input
              :max="100000"
              :show-tooltip="false"
              :step="1000"
              ref="slider"
              class="range-slider"
            />
          </div>
          <el-input
            placeholder="Do"
            v-model.number="priceRangeTo"
            class="price-slider__price"
          >
            <template slot="suffix">Kč</template>
          </el-input>
        </div>
      </div>
        </label>
    </div>
    <el-button
      type="warning"
      @click="formSubmit"
      class="submit-button"
    >
      Zobrazit {{ cruisesCount }} {{ inflectedCruises }}
      <i
        class="el-icon-loading"
        v-if="gettingCount"
      />
    </el-button>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import { redirectPost } from './lib/redirect-post';
import { inflection } from './lib/inflection';

export default {
  data: () => ({
    gettingCount: false,
    priceRangeTo: 0,
    priceRangeFrom: 0,
    cruisesCount: 0,
  }),
  computed: {
    ...mapFields('formState', [
      'destination',
      'kind',
      'ship',
      'port',
      'dateRange',
      'priceRange',
    ]),
    ...mapGetters({
      destinations: 'formValues/getDestinations',
      ships: 'formValues/getShips',
      ports: 'formValues/getPorts',
      dateFrom: 'formState/dateFrom',
      dateTo: 'formState/dateTo',
      kinds: 'formValues/getKinds',
      withTransportAndDelegate: 'formState/withTransportAndDelegate',
      isCruise: 'formState/isCruise',
    }),
    requestData() {
      return {
        destination: this.destination,
        ship: (this.withTransportAndDelegate ? this.ship : null),
        msc_ship: (this.withTransportAndDelegate ? null : this.ship),
        msc_port: this.port,
        starts_at: this.dateFrom,
        ends_at: this.dateTo,
        price_from: this.priceRangeFrom,
        price_to: this.priceRangeTo,
        with_transport_and_delegate: this.withTransportAndDelegate,
        kind: this.kind,
      };
    },
    inflectedCruises() {
      const nameArray = ['zájezd', 'zájezdy', 'zájezdů'];
      return inflection(this.cruisesCount, nameArray);
    },
    showPort() {
      return this.isCruise && !this.withTransportAndDelegate;
    },
    showShip() {
      return this.isCruise;
    },
    showPrice() {
      return this.isCruise;
    },
    endpoint() {
      return this.isCruise === true ? '/cs/plavby' : '/cs/poznavaci-zajezdy';
    },
  },
  methods: {
    resetDestination() {
      if (this.destinations.filter(e => e.id === this.destination).length === 0) {
        if (this.$refs.destination) {
          this.$refs.destination.deleteSelected(new Event('type-changed'));
        }
      }
    },
    resetShip() {
      if (this.ships.filter(e => e.id === this.ship).length === 0) {
        if (this.$refs.ship) {
          this.$refs.ship.deleteSelected(new Event('destination-changed'));
        }
      }
    },
    resetPort() {
      if (this.ports.filter(e => e.code === this.port).length === 0) {
        if (this.$refs.port) {
          this.$refs.port.deleteSelected(new Event('ship-changed'));
        }
      }
    },
    getCounts() {
      if (!this.gettingCount) {
        this.gettingCount = true;
        setTimeout(() => {
          this.axios.get(`${this.endpoint}/results_count`, { params: this.requestData }).then((response) => {
            this.cruisesCount = response.data.crusiesCount;
            this.gettingCount = false;
          });
        }, 500);
      }
    },
    formSubmit() {
      redirectPost(this.endpoint, this.requestData);
    },
  },
  watch: {
    priceRange() {
      this.$refs.slider.$nextTick(() => {
        this.priceRangeFrom = this.priceRange[0];
        this.priceRangeTo = this.priceRange[1];
      });
    },
    priceRangeTo() {
      this.$refs.slider.$nextTick(() => {
        this.$refs.slider.$emit('input', [this.priceRangeFrom, this.priceRangeTo]);
      });
    },
    priceRangeFrom() {
      this.$refs.slider.$nextTick(() => {
        this.$refs.slider.$emit('input', [this.priceRangeFrom, this.priceRangeTo]);
      });
    },
    kind() {
      this.resetDestination();
      this.resetShip();
      this.resetPort();
    },
    ship() {
      this.resetPort();
    },
    destination() {
      this.resetShip();
      this.resetPort();
    },
  },
  mounted() {
    this.getCounts();

    this.$store.watch(state => state, () => {
      this.getCounts();
    }, { deep: true });
  },
};
</script>
